<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{this.formData.sign=='add'? $t('ADD Contract'):$t('EDIT Contract') }}</div>
            </div>
            <template>
                <div class="Info">
                    <el-form  :model="formData" ref="from" :rules="formData.fromaDataRules" label-position="top" v-bind:show-message="notip">
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('ContractNo')" placement="top-start">
                                    <el-form-item :label="$t('ContractNo')" prop="contractNo" label-width="120px"  :rules="formData.fromaDataRules.contractNo">
                                        <el-input v-model.trim="formData.contractNo" style="width: calc(100% - 115px);" maxlength="64" v-bind:disabled="diasabledInput" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('CooperativeCompany')" placement="top-start">
                                    <el-form-item :label="$t('CooperativeCompany')" prop="partnerCorpId" label-width="120px" :rules="formData.fromaDataRules.partnerCorpId">
                                        <el-select v-model="formData.partnerCorpId" placeholder="" filterable style="width: calc(100% - 115px);" clearable>
                                            <el-option v-for="item in this.formData.CooperativeData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('SigningTime')" prop="signDate" label-width="120px"  :rules="formData.fromaDataRules.signDate">
                                    <el-date-picker class="dataicon" v-model="formData.signDate"     value-format="yyyy-MM-dd" 
                                                    type="date" prefix-icon="" style="width: calc(100% - 115px);">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('EffectiveDate')" placement="top-start">
                                    <el-form-item :label="$t('EffectiveDate')" prop="effectiveDate" label-width="120px">
                                        <el-date-picker v-model="formData.effectiveDate"
                                                        type="daterange"
                                                        align="right"
                                                        unlink-panels
                                                        range-separator="-"
                                                        start-placeholder=""
                                                        end-placeholder=""
                                                        value-format="yyyy-MM-dd" 
                                                        style="width: calc(100% - 115px);">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('Brand')" prop="brand" label-width="120px">
                                    <el-select v-model="formData.brand" filterable placeholder="" style="width: calc(100% - 115px);" @change="selectbrand" @focus="loadbrand"  clearable>
                                        <el-option v-for="item in this.formData.dictData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-tooltip class="item" effect="dark" :content="$t('ContractCategory')" placement="top-start">
                                    <el-form-item :label="$t('ContractCategory')" prop="contractTypeName" label-width="120px">
                                        <el-input v-model="formData.contractTypeName" style="width: calc(100% - 115px);" maxlength="64" clearable></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('REMARK')" prop="remark" label-width="120px">
                                    <el-input v-model="formData.remark" style="width: calc(100% - 115px);" maxlength="300" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="smallTit">{{$t('DetailedInfo')}}</div>
                        <el-table :data="formData.tableData" :row-class-name="tableRowClassName"
                                  @current-change="handleCurrentChange" :header-cell-class-name="starAdd">
                            <el-table-column :label="$t('Index')" type="index" width="50px"></el-table-column>
                            <el-table-column v-if="formData.show">
                                <span>{{scope.row.Id}}</span>
                            </el-table-column>
                            <el-table-column :label="$t('ProductCode')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Code}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Code'" :rules="formData.fromaDataRules.Code">
                                        <el-autocomplete v-if="scope.row.isEgdit" v-model="scope.row.Code"
                                                         :fetch-suggestions="pcSearch" clearable></el-autocomplete>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('Product Name')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.ProductName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.ProductName'" >
                                        <el-autocomplete v-if="scope.row.isEgdit" v-model="scope.row.ProductName"
                                                         :fetch-suggestions="pnSearch"
                                                         v-on:change="MGchange(scope.row)" clearable></el-autocomplete>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('COLOR')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Color}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Color'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Color"  maxlength="64" clearable></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('NUMBER')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Number}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Number'" :rules="formData.fromaDataRules.Number">
                                        <el-input-number v-if="scope.row.isEgdit" v-model="scope.row.Number" :controls="false" clearable :min="0" style="width: calc(100% - 20px);" ></el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('UNIT')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.UnitName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.UnitId'" :rules="formData.fromaDataRules.UnitId">
                                        <el-select v-if="scope.row.isEgdit" value-key="id" v-model="scope.row.UnitId" filterable placeholder=" " v-on:change="unitchange(scope.row)" clearable>
                                            <el-option v-for="item in formData.unitData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('REMARK')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Remark}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Remark'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Remark" clearable></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('OPERATION')" align='center'>
                                <template slot-scope="scope">
                                    <el-button @click="deleteItem(scope.$index,scope.row)" :title="$t('Delete')" type="danger" size="small" icon="el-icon-delete" circle></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button :disabled="isSaveDisable" type="primary" @click="submitForm">{{$t('Save')}}</el-button>
                            <el-button @click="resetForm">{{$t('Cancel')}}</el-button>
                        </div>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>

<script>
    import { listInfo, addHead, editHead, addBody, editBody, delBody } from "@/api/contract";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool, TracingStringList } from "@/api/unified";
    export default {
        name: 'Info',
        data() {
            var requiredContractNo = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('ContractNo') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredPartnerCorpId = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('CooperativeCompany') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredSignDate = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('SigningTime') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredProductCode = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('ProductCode  ') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredUnitId = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1)  + '  ' + this.$t('UNIT') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var checkKeepFourPlaces = (rule, value, callback) => {
                const FloatRegex = /^\d{1,14}(\.\d{1,4})?$/;
                if (!FloatRegex.test(value)) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1)  + '  ' + this.$t('NUMBER') + ':' + this.$t('KeepFourPlaces'));
                    callback(new Error(this.$t('KeepFourPlaces')));
                } else { callback(); }

            };
            var checkIsOnly = (rule, value, callback) => {
                TracingUnifiedIsBool("ContractNoExist", this.formData.loadId, value).then(res => {
                    var IsExist = res.data;
                    if (!IsExist && this.formData.sign == 'add') {
                        let indexRule = rule.fullField.split(".")
                        this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1)  + '  ' + this.$t('ContractNo') + ':' + this.$t('ContractNoExists'));
                        callback(new Error(this.$t('ContractNoExists')));
                    } else { callback(); }
                }).catch((erro) => { console.log(erro) });
            };
            var valiNumbPass = (rule, value, callback) => {
                if (Number(value) > 0) {
                    callback();
                } else {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1)  + '  ' + this.$t('NUMBER') + ':' + this.$t('NeedGreater0'));
                    callback(new Error(this.$t('NeedGreater0')));
                }
            };
            return {
                isSaveDisable: false,
                diasabledInput: false, notip: true, notipInfo: "", notiplist: [],notificationPromise: Promise.resolve(),
                formData: {
                    loadId: '', show: false, sign: '',
                    contractNo: '', partnerCorpId: '', contractTypeName: '', remark: '', signDate: null, effectiveDate: ['', ''], brand: '',
                    //隐藏id
                    CooperativeData: [],//合作商
                    unitData: [],//计量单位
                    productData: [],//产品       
                    dictData: [],
                    tableData: [{ Id: '', ProductCode: '', Code: '', Color: '', Number: '', UnitId: '', Remark: '', isEgdit: true, ProductName: '', UnitName: '' }],
                    fromaDataRules: {
                        contractNo: [{ required: true,  validator: requiredContractNo, trigger: 'blur' },
                        { validator: checkIsOnly, trigger: 'blur' }],
                        partnerCorpId: [{ required: true,  validator: requiredPartnerCorpId, trigger: 'blur' }],
                        signDate: [{ required: true,  validator: requiredSignDate, trigger: 'blur' }],
                        Code: [{ validator: requiredProductCode, trigger: 'blur' }],
                        Number: [
                            { type: "number", validator: checkKeepFourPlaces, trigger: 'blur' },
                            { type: "number", validator: valiNumbPass, trigger: 'blur' }
                        ],
                        UnitId: [{  validator: requiredUnitId, trigger: 'blur' }]
                    }
                },
                productCodeData: [],
                productNameData: [],
              
            }
        },

        watch: {
            $route() {
                this.init()
            }
        },// watch的作用可以监控一个值的变换,并调用因为变化需要执行的方法。 //监听路由是否发生变化
        created() {
            ProcessDropDownList('UnitList').then(res => {
                this.formData.unitData = res.data;
            });
            ProcessDropDownList('ProductList').then(res => {
                this.formData.productData = res.data;
            });
            TracingDropDownList('VendorList').then(res => {
                this.formData.CooperativeData = res.data;
            });
            ProcessDropDownList('DictList', 'brand').then(res => {
                this.formData.dictData = res.data;
                let newItem = { value: ' ', label: this.$t('Addone') };
                this.formData.dictData.unshift(newItem);
            });
  
            //成品编码数据源
            this.productCodeData = [];
            TracingStringList("ProductCode").then(res => {
                for (var i = 0; i < res.data.length; i++) {
                    this.productCodeData.push({
                        value: res.data[i]
                    })
                }
            })
            //商品名称数据源
            this.productNameData = [];
            TracingStringList("ProductName").then(res => {
                for (var i = 0; i < res.data.length; i++) {
                    this.productNameData.push({
                        value: res.data[i]
                    })
                }
            })
            this.init()// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
        },//加载
        methods: {//加载数据
            init() {
                this.formData.sign = this.$route.query.sign;
                this.formData.loadId = this.$route.query.ID;
                if (this.formData.sign == 'edit') {
                    this.diasabledInput = true;
                    this.getListInfo();
                }
            },    //删除数据
            deleteItem(index, row) {
                this.$confirm(this.$t('IsDel') + row.ProductName, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    if (row.Id == '') {
                        this.formData.tableData.splice(index, 1);
                    }
                    else {
                        delBody(row.Id).then(() => {
                            //待删除
                            this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                            this.formData.tableData.splice(index, 1);
                        });
                    }
                })

            },//点击行变化
            handleCurrentChange(currentRow, oldCurrentRow) {
                if (currentRow.index == this.formData.tableData.length - 1) {
                    this.addrow();
                }
                this.$set(currentRow, 'isEgdit', true);
                if (oldCurrentRow != null && oldCurrentRow != undefined) {
                    this.$set(oldCurrentRow, 'isEgdit', false);
                }
            },//提交数据
            submitForm() {
                this.$refs["from"].clearValidate() // 移除校验结果
                for (var j = this.formData.tableData.length-1; j> -1; j--) {
                    if (this.formData.tableData[j].ProductName == '' && this.formData.tableData[j].Code.trim() == '' && this.formData.tableData[j].Color.trim() == ''
                        && this.formData.tableData[j].UnitName == '' && this.formData.tableData[j].Remark.trim() == '' && (this.formData.tableData[j].Number == 0||this.formData.tableData[j].Number == '')) {
                        this.formData.tableData.splice(j, 1);
                    }
                }
                if (this.formData.tableData.length== 0) {
                    this.addrow();
                    this.$message({
                        message: this.$t('No Data Submitted'),
                        type: "error"
                    });//提示无数据
                    return false;
                } else {
                    this.$nextTick(() => {//避免点击两次
                        this.notip = false;
                        this.notiplist = [];
                        let formName = "from";
                        this.$refs[formName].validate((valid) => {
                            this.isSaveDisable = true;
                            if (valid) {
                                let headrow = {
                                    id: this.formData.loadId,
                                    brand: this.formData.brand.trim(),
                                    code: this.formData.contractNo.trim(),
                                    vendorCorporationId: this.formData.partnerCorpId,
                                    type: this.formData.contractTypeName.trim(),
                                    remark: this.formData.remark.trim(),
                                    signDate: this.formData.signDate != '' ? this.formData.signDate : '1990-01-01T12:00',
                                    beginDate: this.formData.effectiveDate[0] != '' ? this.formData.effectiveDate[0] : '1990-01-01T12:00',
                                    endDate: this.formData.effectiveDate[1] != '' ? this.formData.effectiveDate[1] : '1990-01-01T12:00',
                                    status: 0,
                                    createUserid: '',
                                    updateUserid: '',
                                    departmentId: '',
                                    corporationId: '',
                                };
                                if (this.formData.loadId != '') {
                                    editHead(headrow).then(() => {
                                        this.formData.tableData.forEach(v => {
                                            let paramrow = {
                                                id: v.Id,
                                                contractId: this.formData.loadId,
                                                indexNumber: v.index,
                                                productCode: v.ProductCode,
                                                productName: v.ProductName,
                                                code: v.Code.trim(),
                                                model:'',
                                                color: v.Color.trim(),
                                                remark: v.Remark.trim(),
                                                number: v.Number,
                                                unitId: v.UnitId,
                                                status: 0,
                                                createUserid: '',
                                                updateUserid: '',
                                                departmentId: '',
                                                corporationId: '',
                                            };
                                            if (v.Id != '') {
                                                editBody(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                            if (v.Id == '') {
                                                addBody(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                        })
                                        this.$message({
                                            message: this.$t('OperationSuccess'),
                                            type: "success"
                                        });
                                        this.isSaveDisable = false;
                                        this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                                        this.$router.push({ path: '/pvh_factory/contractList' });
                                    }).catch((erro) => { console.log(erro) });
                                }
                                if (this.formData.loadId == '') {
                                    addHead(headrow).then(res => {
                                        this.formData.tableData.forEach(v => {
                                            let paramrow = {
                                                id: v.Id,
                                                contractId: res.data.result,
                                                indexNumber: v.index,
                                                productCode: v.ProductCode,
                                                productName: v.ProductName,
                                                model: '',
                                                code: v.Code.trim(),
                                                color: v.Color.trim(),
                                                remark: v.Remark.trim(),
                                                number: v.Number,
                                                unitId: v.UnitId,
                                                status: 0,
                                                createUserid: '',
                                                updateUserid: '',
                                                departmentId: '',
                                                corporationId: '',
                                            };
                                            if (v.Id != '') {
                                                editBody(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                            if (v.Id == '') {
                                                addBody(paramrow).then().catch((erro) => { console.log(erro) });
                                            }
                                        })
                                        this.$message({
                                            message: this.$t('OperationSuccess'),
                                            type: "success"
                                        });
                                        this.isSaveDisable = false;
                                        this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                                        this.$router.push({ path: '/pvh_factory/contractList' });
                                    }).catch((erro) => { console.log(erro) });
                                    }
                            } else {
                                this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                                    let newDatas = [];
                                    const h = this.$createElement;
                                    for (let i in this.notiplist) {
                                        newDatas.push(h('p', null, this.notiplist[i]));
                                    }
                                    this.$notify.error({
                                        title: this.$t('OperationFailed'),
                                        offset: 100,
                                        showClose: true,
                                        duration: 0,
                                        message: h('div', null, newDatas),
                                        customClass: 'notifyStyle',
                                    });
                                });
                                return false;
                            }
                        });
                    });
                }
            },//保存按钮
            resetForm() {
                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value == this.$route.fullPath), 1);
                this.$router.push({ path: '/pvh_factory/contractList' });
            },//取消按钮
            starAdd(obj) {
                if (obj.columnIndex === 1 || obj.columnIndex === 5 || obj.columnIndex === 4 ) {
                    return 'star';
                }
            },//列头加星号
            tableRowClassName({ row, rowIndex }) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },//加index
            addrow() {
                let item = { Id: '', ProductCode: '', Code: '', Color: '', Number: '', UnitId: '', Remark: '', isEgdit: false, ProductName: '', UnitName: '' }
                this.formData.tableData.push(item);      // 把每一行的索引放进row
            },//新增行
            getListInfo() {
                listInfo(1, 50, '', '', '', '', '', '', '', '', this.formData.loadId, '').then(res => {
                    this.formData.tableData = [];
                    var infodata = res.data.Contracts;
                    this.formData.contractNo = infodata[0].Code;
                    this.formData.partnerCorpId = infodata[0].VendorCorporationId;
                    this.formData.contractTypeName = infodata[0].Type;
                    this.formData.brand = infodata[0].Brand;
                    this.formData.remark = infodata[0].Remark;
                    this.formData.signDate = infodata[0].SignDate != '1990-01-01T12:00' ? infodata[0].SignDate: '';
                    var Btime = infodata[0].BeginDate != '1990-01-01T12:00' ? infodata[0].BeginDate: '';
                    var Etime = infodata[0].EndDate != '1990-01-01T12:00' ? infodata[0].EndDate : '';
                    this.formData.effectiveDate = [Btime, Etime]; 
                    this.formData.tableData = infodata[0].ContractDetails;
                    if (this.formData.tableData.length == 0) { this.addrow(); }
                }).catch((erro) => { console.log(erro) });
            }
            , MGchange(row) {
                console.log("row", row);
                console.log("this.formData.productData", this.formData.productData);
                var objrow = {};
                objrow = this.formData.productData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.label === row.ProductName;//筛选出匹配数据
                });
                console.log("objrow", objrow);
                if (objrow == undefined) {

                } else {

                    row.ProductCode = objrow.value;
                }
            },
            unitchange(row) {
                if (row.UnitId != "") {
                var objrow1 = {};
                objrow1 = this.formData.unitData.find((i) => {//这里的userList就是上面遍历的数据源
                    return i.value === row.UnitId;//筛选出匹配数据
                });
                    row.UnitName = objrow1.label;
                } else {
                    row.UnitName = "";
                }
            },
            selectbrand(value) {
                if (value == ' ') {
                  this.formData.brand = '';
                    this.$router.push({ path: '/basic/dic', query: { DicId: 'brand' }  })
                }
            },
            pcSearch(queryString, cb) {
                var restaurants = this.productCodeData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            pnSearch(queryString, cb) {
                var restaurants = this.productNameData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            loadbrand() {
                ProcessDropDownList('DictList', 'brand').then(res => {
                    this.formData.dictData = res.data;
                    let newItem = { value: ' ', label: this.$t('Addone') };
                    this.formData.dictData.unshift(newItem);
                });
            },
        }
        ,
        mounted() {

        }
    }
</script>


<style>
    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .ttt {
        width: 100px;
    }

    .searlable .el-checkbox__label {
        color: #8CA0B3;
        font-weight: 500;
    }

    .smallTit {
        margin: 1.3rem 0 1.25rem;
    }

    .formD {
        padding: 0 40px;
    }
    .el-row_style {
        display: flex;
        flex-wrap: wrap;
        height: 90px;
    }

    .el-col_style {
        height: 45px;
    }
    .el-form--label-top .el-form-item__label {
        float: none;
        /*  display: inline-block;*/
        text-align: left;
        padding: 0 0 0px;
    }
</style>